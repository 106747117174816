<template>
    <div>
        <ul class="nav nav-tabs"
            id="myTab"
            role="tablist">
            <li v-for="(year, index) in this.getYears"
                class="nav-item"
                :class="index == 0 ? 'active': ''"
                role="presentation">
                <a class="nav-link"
                    :class="index == 0 ? 'active': ''"
                    :id="getYearButtonId(year)"
                    data-toggle="tab"
                    :href="getTarget(year)"
                    @click="setActiveTab(year)"
                    role="tab"
                    :aria-controls="getTabId(year)"
                    :aria-selected="index == 0 ? true : false">{{year}}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link"
                    id="textra-tab"
                    data-toggle="tab"
                    href="#textra"
                    @click="setActiveTab('extra')"
                    role="tab"
                    aria-controls="textra"
                    aria-selected="false">Zusatzleistungen</a>
            </li>


            <!-- <li class="nav-item">
                <a class="nav-link"
                    id="guestcard-tab"
                    data-toggle="tab"
                    href="#guestcard"
                    @click="setActiveTab('guestcard')"
                    role="tab"
                    aria-controls="guestcard"
                    aria-selected="false">Gästekarte</a>
            </li> -->

        </ul>


        <div class="tab-content"
            id="priceTabContent">
            <div v-for="(year, index) in this.getYears"
                class="tab-pane"
                :class="index == 0 ? 'active' : ''"
                :id="getTabId(year)"
                role="tabpanel"
                :aria-labelledby="getTabAreaLabel(year)">

                <table class="table pricelist-table table-hover">
                    <thead>
                        <tr>
                            <td class="mainrow">Zeitraum</td>
                            <td class="mainrow text-center">1 - 3 Nächte</td>
                            <td class="mainrow text-center">ab 4 Nächte</td>
                            <td>&nbsp;</td>
                        </tr>
                    </thead>

                    <tbody v-for="sg in priceArray">
                        <tr v-for="price in sg.lines"
                            class="subrow"
                            v-if="isDateInRange(price.fromdate,year)  && sg.service.type == 'RENT' && sg.nightRange.max == 3">
                            <td class="subinfo">
                                <span v-if="price.fromdate">{{getFormattedDate(price.fromdate)}} - {{getFormattedDate(price.tilldate)}}</span>
                                <br/><small>{{price.season}}</small>
                            </td>
                            <td class="text-end price text-center">
                                <span class="text-nowrap"
                                    v-if="price.value">{{getCalculatedPrice(price.value)}}</span>
                                <span class="text-nowrap"
                                    v-if="sg.service.calculation === 'USAGE'">{{voMsg('tpl.service.USAGE')}}</span>
                            </td>
                            <td class="text-end price text-center">
                                <span class="text-nowrap"
                                    v-if="price.value">{{getCalculatedPrice2(price.fromdate, price.tilldate, price.season)}}</span>
                            </td>
                            <td>
                                pro Nacht
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="tab-pane"
                id="textra"
                role="tabpanel"
                aria-labelledby="textra-tab">
                <table class="table pricelist-table table-hover">
                    <thead>
                        <tr>
                            <td class="mainrow">Leistung</td>
                            <td class="mainrow text-center">Preis</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                    </thead>
                    <tbody v-for="sg in priceArray">
                        <tr :class="{'mainrow': sg.lines && sg.lines.length > 0}"
                            v-if="sg.service.type != 'RENT' && sg.service._id != 41760 && sg.service._id != 21480">
                            <td>{{sg.service.name}}</td>
                            <td class="text-end text-center price">
                                <span class="text-nowrap"
                                    v-if="sg.price">{{getCalculatedPrice(sg.price)}}</span>
                                <span class="text-nowrap"
                                    v-if="sg.service.calculation === 'USAGE'">{{voMsg('tpl.service.USAGE')}}</span>
                            </td>

                            <td class="text-end text-center">&nbsp;</td>
                            <td class="text-end text-center">&nbsp;</td>
                        </tr>

                        <tr v-for="price in sg.lines"
                            class="subrow"
                            v-if="sg.service.type != 'RENT' && sg.service._id != 41760 && sg.service._id != 21480 ">
                            <td class="subinfo">
                                <span v-if="price.fromdate">{{getFormattedDate(price.fromdate)}} - {{getFormattedDate(price.tilldate)}}</span>
                                <br/><small>{{price.season}}</small>
                            </td>

                            <td class="text-end text-center calc">
                                <span v-if="sg.service.calculation === 'FLAT'">{{voMsg('tpl.service.FLAT')}}</span>
                                <span v-if="sg.service.calculation === 'NIGHT'">{{voMsg('tpl.service.NIGHT')}}</span>
                                <span v-if="sg.service.calculation === 'WEEK'">{{voMsg('tpl.service.WEEK')}}</span>
                                <span v-if="sg.service.calculation === 'MONTH'">{{voMsg('tpl.service.MONTH')}}</span>
                                <span v-if="sg.service.calculation === 'USAGE'">{{voMsg('tpl.service.USAGE')}}</span>
                            </td>
                            <td class="text-end text-center price">
                                <span class="text-nowrap"
                                    v-if="price.value">{{getCalculatedPrice(price.value)}}</span>
                                <span class="text-nowrap"
                                    v-if="sg.service.calculation === 'USAGE'">{{voMsg('tpl.service.USAGE')}}</span>
                            </td>
                            <td class="text-end text-center price">
                                <span class="text-nowrap"
                                    v-if="price.value">{{getCalculatedPrice(price.value)}}</span>
                                <span class="text-nowrap"
                                    v-if="sg.service.calculation === 'USAGE'">{{voMsg('tpl.service.USAGE')}}</span>
                            </td>
                        </tr>
                    </tbody>

                    <tbody v-for="sg in priceArray">
                        <tr :class="{'mainrow': sg.lines && sg.lines.length > 0}"
                            v-if="sg.service._id == 41760">
                            <td class="mainrow">
                                Gästekarte
                            </td>

                            <td class="text-end text-center mainrow">
                                Kind 3 bis 15 Jahre (Dornum)
                            </td>

                            <td class="text-end price text-center mainrow">
                                Erwachsene ab 16 Jahre (Dornum)
                            </td>
                            <td>
                                &nbsp;
                            </td>
                        </tr>
                        <tr v-for="price in sg.lines"
                            class="subrow"
                            v-if="sg.service._id == 41760">
                            <td class="subinfo">
                                <span v-if="price.fromdate">{{getFormattedDate(price.fromdate)}} - {{getFormattedDate(price.tilldate)}}</span>
                                <br/><small>{{price.season}}</small>
                            </td>

                            <td class="text-end price text-center">
                                <span class="text-nowrap"
                                    v-if="price.value">{{getCalculatedPriceForKids(price.fromdate, price.tilldate)}}</span>
                            </td>
                            <td class="text-end price text-center">
                                <span class="text-nowrap"
                                    v-if="price.value">{{getCalculatedPrice(price.value)}}</span>
                            </td>
                            <td class="text-end price">
                                pro Nacht
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>


            <!-- <div class="tab-pane"
                id="guestcard"
                role="tabpanel"
                aria-labelledby="guestcard-tab">
                <table class="table pricelist-table table-hover">
                    <tbody v-for="sg in priceArray">
                        <tr :class="{'mainrow': sg.lines && sg.lines.length > 0}"
                            v-if="sg.service._id == 41760">
                            <td class="mainrow">
                                Gästekarte
                            </td>

                            <td class="text-end text-center mainrow">
                                Kind 3 bis 15 Jahre (Dornum)
                            </td>

                            <td class="text-end price text-center mainrow">
                                Erwachsene ab 16 Jahre (Dornum)
                            </td>
                            <td>
                                &nbsp;
                            </td>

                        </tr>
                        <tr v-for="price in sg.lines"
                            class="subrow"
                            v-if="sg.service._id == 41760">
                            <td class="subinfo">
                                <span v-if="price.fromdate">{{getFormattedDate(price.fromdate)}} - {{getFormattedDate(price.tilldate)}}</span>
                                <br/><small>{{price.season}}</small>
                            </td>

                            <td class="text-end price text-center">
                                <span class="text-nowrap"
                                    v-if="price.value">{{getCalculatedPriceForKids(price.fromdate, price.tilldate)}}</span>
                            </td>
                            <td class="text-end price text-center">
                                <span class="text-nowrap"
                                    v-if="price.value">{{getCalculatedPrice(price.value)}}</span>
                            </td>
                            <td class="text-end price">
                                pro Nacht
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> -->

        </div>

    </div>
</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'seasonprices',
    	props: {
    		pricelist: String
    	},
    	data: function() {
    		return {
    			priceArray: {},
    			years: Array,
    			unitId: Number,
    			priceBookingFee: Number,
    			priceFinalCleaning: Number,
    			rentalPrices: Array,
    			mandatoryPrices: Array,
    			optionalPrices: Array,
    			surchargePrices: Array
    		};
    	},
    	mounted: function() {
    		var pricelist = JSON.parse(this.pricelist);
    		this.priceArray = pricelist;
    	},
    	methods: {
    		setActiveTab: function(year) {
    			var className = "t" + year;
    			$(".tab-pane").removeClass('active');
    			$('.tab-pane').each(function(i, obj) {
    				var $obj = $(obj);
    				if (obj.id == className) {
    					$obj.addClass('active');
    				}
    			});
    		},
    		getYearButtonId: function(year) {
    			return 't' + year + '-tab';
    		},
    		getTarget: function(year) {
    			return "#t" + year;
    		},
    		getTabId: function(year) {
    			return "t" + year;
    		},
    		getTabAreaLabel: function(year) {
    			return "t" + year + "-tab";
    		},
    		getFormattedDate: function(date) {
    			return DateEx.formatDate(date, 'dd.MM.yy', 'de');
    		},
    		getCalculatedPrice2: function(fromdate, tilldate, seasonName) {
    			var pricelist = this.priceArray;
    			var i, j;
    			for (i = 0; i < pricelist.length; i++) {
    				var service = pricelist[i];
    				for (j = 0; j < service.lines.length; j++) {
    					var price = service.lines[j];
    					if (price.type == 'RENT' && price.nightRange && price.nightRange.min == 4 && price.fromdate == fromdate && price.tilldate == tilldate) {
    						return this.getCalculatedPrice(price.value);
    					}
    				}
    			}
    			return this.getCalculatedPrice(0);
    		},
    		getCalculatedPriceForKids: function(fromdate, tilldate) {
    			var pricelist = this.priceArray;
    			var i, j;
    			for (i = 0; i < pricelist.length; i++) {
    				var service = pricelist[i];
    				if (service.service._id == 21480) {
    					for (j = 0; j < service.lines.length; j++) {
    						var price = service.lines[j];
    						if (service.service._id == 21480 && price.fromdate == fromdate && price.tilldate == tilldate) {
    							return this.getCalculatedPrice(price.value);
    						}
    					}
    				}
    			}
    			return this.getCalculatedPrice(0);
    		},
    		getCalculatedPrice: function(price) {
    			var rentPrice = 0;
    			var calcPrice = 0;
    			if (price > 0) {
    				rentPrice = price;
    				calcPrice = Number((rentPrice) / 100).toLocaleString("de-DE", {
    					minimumFractionDigits: 2
    				}) + " €";
    			}
    			return calcPrice;
    		},
    		getCalculatedMandatoryPrice: function() {
    			var rentPrice = 0;
    			var calcPrice = 0;
    			calcPrice = Number((this.priceBookingFee + this.priceFinalCleaning) / 100).toLocaleString("de-DE", {
    				minimumFractionDigits: 2
    			}) + " €";

    			return calcPrice;
    		},
    		isDateInRange: function(fromdate, year) {
    			var fromYear = parseInt(fromdate.substring(0, 4));
    			if (fromYear == year) {
    				return true;
    			}
    			return false;
    		}

    	},
    	computed: {
    		getYears: function() {
    			var pricelist = JSON.parse(this.pricelist);

    			var years = [];

    			var i, j;
    			for (i = 0; i < pricelist.length; i++) {
    				for (j = 0; j < pricelist[i].lines.length; j++) {
    					if (pricelist[i].lines[j].fromdate) {
    						var year = pricelist[i].lines[j].fromdate.substring(0, 4);
    						if (years.indexOf(year) < 0) {
    							years.push(year);
    						}
    					}
    				}
    			}
    			return years;
    		}
    	}
    };
</script>